import '../styles/main.css'

import 'lazysizes'

import 'prismjs'
import 'prismjs/components/prism-clike'
import 'prismjs/components/prism-markup-templating'
import 'prismjs/components/prism-php'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-markup'
import 'prismjs/components/prism-css'

import 'what-input'

import { activityChart } from './_activity-chart'
import { svgBarChart } from './_svg-bar-chart'

activityChart()
svgBarChart()
