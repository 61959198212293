import chroma from 'chroma-js'

export const svgBarChart = (): void => {
  const charts = document.querySelectorAll('.js-svg-bar-chart')

  if (charts.length === 0) {
    return
  }
  const chartsArray = [].slice.call(charts) as SVGElement[]

  chartsArray.forEach((el) => {
    const minAttr = el.getAttribute('data-min')
    const maxAttr = el.getAttribute('data-max')
    const rectElements = el.querySelectorAll('rect')

    if (minAttr === null || maxAttr === null || rectElements.length === 0) {
      return
    }

    const min = +minAttr || 0
    const max = +maxAttr

    const scale = chroma.scale('YlGnBu').domain([min, max]).out('hex')

    const rectsArray = [].slice.call(rectElements) as SVGRectElement[]
    rectsArray.forEach((rect) => {
      const valueAttr = rect.getAttribute('data-value')

      if (valueAttr === null) {
        return
      }

      const value = +valueAttr || 0

      rect.setAttribute('fill', scale(value))
    })
  })
}
