enum Period {
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

type Activity = {
  label: string
  distance: number
  duration: number
  elevation: number
}

type Arr = {
  [P in Period]: Activity[]
}

export const activityChart = (): void => {
  const chart = document.querySelector('.js-activity-chart') as HTMLUListElement
  const select = document.querySelector('.js-activity-period') as HTMLSelectElement

  if (!chart || !select) {
    return
  }

  const data = chart.getAttribute('data-activities')

  if (!data) {
    return
  }

  const dataObj = JSON.parse(data) as Arr

  const renderChart = (period: Period) => {
    const biggestDistance = dataObj[period]
      .map(({ distance }) => distance)
      .sort(function (a, b) {
        return b - a
      })[0]

    const height = (distance: number, base: number) => (distance / base) * 100
    const width = (1 / dataObj[period].length) * 100

    const result = dataObj[period]
      .map(({ distance, label }) => {
        const h = height(distance, biggestDistance)
        return `<li style='height: ${h}%; width: ${width}%;' data-label='${label}'><span class='visually-hidden'>${distance}</span></li>`
      })
      .join('')

    chart.innerHTML = result
  }

  const renderSelectOptions = () => {
    const options = Object.keys(dataObj)
      .map((el) => `<option value='${el}'>${el}</option>`)
      .join('')
    select.innerHTML = options
  }

  renderSelectOptions()

  renderChart(Period.weekly)

  select.addEventListener('change', () => {
    renderChart(select.value as Period)
  })
}
